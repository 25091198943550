import { Injectable } from '@angular/core';
import { SiteConfig } from '../../config/site-config';
import { ApiService } from '../api-service/api.service';
import { DataService } from '../data-service/data.service';
import { SharedService } from '../shared.service/shared.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public token: string;
  security: any = {
    Source: 'VIA',
    SourceToken: '997142F016F0715A20381868218925510F5553C0',
  };

  constructor(
    private dataService: DataService,
    private sharedService: SharedService,
    private api: ApiService
  ) {}

  setSiteData(token) {
    localStorage.setItem(
      SiteConfig.localStorageNames.siteAuthToken,
      JSON.stringify(token)
    );
    this.dataService.siteToken = token;
  }
  setLoginData(data) {
    localStorage.setItem(
      SiteConfig.localStorageNames.viagooseLoginData,
      JSON.stringify(data)
    );
    localStorage.setItem(
      SiteConfig.localStorageNames.viagooseLoginStatus,
      'true'
    );
    this.dataService.loginStatus = true;
    this.dataService.userData = data;
    if (data.ratekey && data.ratekey >= 1) {
      this.dataService.rateKey = data.ratekey;
    }
  }
  getLoginDataFromLocalStorage() {
    let loginData = localStorage.getItem(
      SiteConfig.localStorageNames.viagooseLoginData
    );
    if (loginData) {
      this.dataService.userData = null;
      this.dataService.loginStatus = false;
    } else {
      loginData = JSON.parse(loginData);
      this.dataService.userData = loginData;
      this.dataService.loginStatus = true;
    }
  }
  getSiteTokenFromStorage(apiCall = false) {
    let token = localStorage.getItem(
      SiteConfig.localStorageNames.siteAuthToken
    );
    if (token && !apiCall) {
      token = JSON.parse(token);
      this.dataService.siteToken = token;
    } else {
      this.sharedService.getSiteToke().subscribe((response: any) => {
        if (response && response.token) {
          this.setSiteData(response.token);
        }
      });
    }
  }
  getLoginStatusFromStorage() {
    let loginStatus = localStorage.getItem(
      SiteConfig.localStorageNames.viagooseLoginStatus
    );
    if (loginStatus) {
      loginStatus = JSON.parse(loginStatus);
      if (loginStatus) {
        this.dataService.loginStatus = true;
        let loginData: any = localStorage.getItem(
          SiteConfig.localStorageNames.viagooseLoginData
        );
        if (loginData) {
          loginData = JSON.parse(loginData);
          if (loginData) {
            this.dataService.userData = loginData;
            if (loginData.ratekey && loginData.ratekey > 1) {
              this.dataService.rateKey = loginData.ratekey;
            }
          }
        }
      }
    }
  }

  isPointUser(): boolean {
    let loginData: object = JSON.parse(
      localStorage.getItem(SiteConfig.localStorageNames.viagooseLoginData)
    );
    let points_rate = parseInt(loginData['points_rate']);
    return !isNaN(points_rate) && points_rate > 0;
  }
}
