import { Component } from '@angular/core';
import { Data, NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common-service/common.service';
import { DataService } from './services/data-service/data.service';
import { SharedService } from './services/shared.service/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'viagoose-new';
  constructor(
    private sharedService: SharedService,
    private dataService: DataService,
    private commonSerive: CommonService,
    private router: Router
  ) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          console.clear();
        }, 2000);
      }
    });
    this.commonSerive.getSiteTokenFromStorage();
    this.commonSerive.getLoginStatusFromStorage();
  }

  get showLoader(): boolean {
    return this.dataService.loaderStatus;
  }
}
